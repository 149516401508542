<template>
    <div class="app-indexpage traceinfopage growpage u-p-30 u-rela">
        <img
                class="imgbg imgbg5 u-abso"
                src="../../assets/images/applet/index/bg5.png"
                alt=""
        />
        <div class="bggray radius-60">
            <!-- 加工仓储 -->
            <div class="u-p-22 u-p-t-40 u-rela">
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">加工仓储</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <p class="u-tips-color u-font-32 u-m-t-20">
                    当地政府通过数字农业系统对红糖加工以及原材甘蔗种植进行全方位监管
                </p>
                <div class="panel u-rela index-1">
                    <!-- 第一道工序 -->
                    <div>
                        <div id="sort_1" class="u-text-center">
                            <div
                                    class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                            >
                                <span>第一道工序：原产地控制</span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            红糖的原材料都是甘蔗，而熬制老红糖的甘蔗十分讲究。必须采用好山好水养育出来的，通过数字化农业赋能使得甘蔗营养价值更高。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>
                    <!-- 第二三道工序 -->
                    <div class="u-m-t-30">
                        <div id="sort_2" class="u-text-center moudle">
                            <div
                                    class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                            >
                                <span>第二-四道工序：<br/>择时-回糖-精心选材</span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            古法酿造最遵循的一个原则就是：顺应天时。每年的立冬到清明期间，是老蔗最好的收货季节，此时的甘蔗表面布满了白霜，这层白霜是天地之间的自然精华，中国有句俗话叫做：白霜老秋蔗，白霜可以入药，富含人体多种微量元素，所以榨甘蔗汁要连皮带肉一起榨，这样做出来的红糖才补。因此，制作红糖的甘蔗是无需清洗的。如同葡萄酿酒一般。收货的甘蔗，适当的存放几天，糖分能够更好的凸显。俗称“回糖”。回糖后开始选材，并不是整根老蔗都可以用来熬制红糖，头硬尾苦，必须砍去；仅仅采取中部位置，用于原材料。如此下来，一整根甘蔗往往只剩下1/2。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>
                    <!-- 第四道工序 -->
                    <div class="u-m-t-30">
                        <div id="sort_3" class="u-text-center">
                            <div class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto">
                <span>第五-八道工序：<br/>压榨-去除杂质-收浆-沉淀过滤
              </span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            经过压榨的甘蔗汁流入存放池，去除表面泡沫后，初步沉淀。选取中间层杂质稀少的甘蔗汁进行过滤，后入锅熬制。再将第一次收浆的糖汁，进行过滤。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>
                    <!-- 第五-八道工序 -->
                    <div class="u-m-t-30">
                        <div id="sort_4" class="u-text-center">
                            <div
                                    class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                            >
                                <span>第九-十三道工序：<br/>熬制-开泡-撇泡--蒸发-出糖</span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            过滤后的糖汁便可正式开始熬糖。而熬糖的火候很有讲究，通常是大火开泡，小火撇泡，猛火蒸发，微火出糖。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>
                    <!-- 第九-十三道工序 -->
                    <div class="u-m-t-30">
                        <div id="sort_5" class="u-text-center">
                            <div
                                    class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                            >
                                <span>第十四道工序：<br/>赶水</span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            就到了微火出糖的好时候，此时的糖水浓度很大，需要连续不断的搅拌，搅拌的过程就叫做赶水。字面意思就是赶走水分的意思。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>
                    <!-- 第十四道工序 -->
                    <div class="u-m-t-30">
                        <div id="sort_6" class="u-text-center">
                            <div
                                    class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                            >
                <span> 第十五-十八道工序：<br/>打砂-倒模成型-冷却-包装上市
              </span>
                            </div>
                        </div>
                        <p class="u-font-28 u-content-color u-m-t-20">
                            赶完水的糖浆不能再继续熬制，需快速舀至凉盆，自然冷却，同时不停的搅拌使糖浆内外温度均匀的降下来，刺激红糖结晶，也就是通常所说的打砂。打砂的好坏会直接影响最后红糖的质量跟口感。打砂后的糖浆，如同蜂蜜般粘稠。略带晶莹剔透的质感。这时候的糖浆即将凝固但还没有凝固，需在极短的时间内，分装到事先准备好的小模具中，倒糖成形。自然冷却后进行包装储存。
                        </p>
                        <!-- 操作人 -->
                        <!--            <div class="panel caozuoren u-m-t-40">-->
                        <!--              <div class="u-flex u-row-between u-font-28">-->
                        <!--                <span class="u-content-color">操作人：王某</span>-->
                        <!--                <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5"-->
                        <!--                  >2022.4.19</span-->
                        <!--                >-->
                        <!--              </div>-->
                        <!--              <div class="u-flex u-row-between u-m-t-30">-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img18.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img19.png" />-->
                        <!--                </div>-->
                        <!--                <div class="u-flex u-flex-col">-->
                        <!--                  <span class="u-font-22 u-tips-color u-m-b-15">标题</span>-->
                        <!--                  <img src="../../assets/images/applet/index/img20.png" />-->
                        <!--                </div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {};
        },
        created() {
            this.$nextTick(() => {
                this.getlocal()
            })
        },
        mounted() {
            this.$nextTick(function () {
                window.addEventListener('scroll', this.handleScroll)
            })
        },
        methods: {
            getlocal() {
                let sort_id = this.$route.query.sort_id;
                if (sort_id) {
                    let anel = document.getElementById(sort_id);
                    anel.scrollIntoView();
                }
            }
        }
    };
</script>
<style lang="scss">
    @import "../../assets/css/applet.scss";
</style>
